import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Immobile Choreography`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition Catalogue, 2019`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Essays by Professor David Lurie, Beverley Hood and Dr Silvia Casini`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Grampian Health Art Trust, Aberdeen, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978-0-9567756-5-8`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://www.ghat-art.org.uk/product/immobile-choreography/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`http://www.ghat-art.org.uk/product/immobile-choreography/`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Hybrid to Simulated Invention`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Explorations in Art & Technology (Second Edition), 2018`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by Linda Candy, Ernest Edmonds and Fabrizio Poltronieri`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Springer Verlag, London, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: ISBN 978-1-4471-7367-0`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.springer.com/gp/book/9781447173663",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://www.springer.com/gp/book/9781447173663`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Eidolon – Beverley Hood`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Monograph to accompany the Eidolon immersive performance project, 2016`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Essays by Dr Michael Moneypenny, Beverley Hood, Nicola White and Marina Warner. Photography by Beverley Hood, Lindsay Perth, Emma Bowan and Alicia Bruce. Designed by Marco Scerri.`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by the artist`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978-1-5262-0490-5`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:info@bhood.co.uk",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Available from the artist`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Eidolon: The technological body`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Technoetic Arts: A Journal of Speculative Research, Volume 14, Number 3, 2016`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by Roy Ascott and Luna Zhou`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Intellect, Bristol, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISSN: 1477-965X (Print); ISSN: 1758-9533 (Online)`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.ingentaconnect.com/content/intellect/ta/2016/00000014/00000003/art000033",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://www.ingentaconnect.com/content/intellect/ta/2016/00000014/00000003/art000033`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Update_5: Technology as Context. International Art Exhibition`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition Catalogue, 2014`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Liedts-Meesen Foundation, Ghent, Belgium`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978 94 9177 575 8`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Locating the Territory`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`(co-authored with Chris Speed, Ben Buchart, Janet Dickinson, Julia Hibbert)`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISEA 2014 Conference Proceedings, 2015`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by Dr. Thorsten Lomker, Janet Bellotto, Adina Hempel and Dr. Woodman Taylor`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Zayed University Books, Dubai, UAE`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978-9948-18-239-9`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISSN: 2451-8603 (Printed Proceedings);ISSN: 2451-8611 (Online Proceedings)`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://www.thecatandthecoup.com/ISEA2014_Proceedings.pdf",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`http://www.thecatandthecoup.com/ISEA2014_Proceedings.pdf`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Human Race: Inside the history of sports medicine`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition catalogue, 2012`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by Kitty Chilcott and Malcolm MacCallum`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Scotland & Medicine: Collections & Connections`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978-0-9553139-3-6`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Glitching`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Transdisciplinary Imaging Conference Proceedings, 2012`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by: Su Baker, Paul Thomas and Andrew Varano`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Transdisciplinary Imaging Conference 2012 Sydney, Australia`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978-0-9807186-8-3`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://blogs.unsw.edu.au/tiic/files/2013/03/Transimage_conference_proceedings2012.pdf",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`http://blogs.unsw.edu.au/tiic/files/2013/03/Transimage_conference_proceedings2012.pdf`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Choreographing the glitch`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Physicality 2012, 4th International Workshop on Physicality, 2012`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Proceedings of HCI 2012, The 26th BCS Conference on Human Computer Interaction`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by The Chartered Institute for IT - eWiC Series`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISSN: 1477-9358`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://ewic.bcs.org/content/ConWebDoc/48849",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://ewic.bcs.org/content/ConWebDoc/48849`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Reflection: Contemporary Visual Arts and Crafts in Edinburgh`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition catalogue, 2011`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by the City of Edinburgh Council`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`trails: Akiyoshidai International Art Village 10th Anniversary`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Residency publication, 2009`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Akiyoshidai International Art Village, Yamaguchi, Japan`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 4-902141-07-8 C0070  `}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Between East and West – Yasuko Toyashima in conversation with Beverley Hood`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Cross-Wired: Communication, Interface, Locality (Transcript), 2004`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by Kirsten Mey and Simon Yuill`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Manchester University Press`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 978-0719070365`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.amazon.co.uk/Cross-Wired-Communication-Interface-Locality-Transcript/dp/0719070368",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://www.amazon.co.uk/Cross-Wired-Communication-Interface-Locality-Transcript/dp/0719070368`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BLIND SIGHT`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition publication, 2004`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Salty Press, Finland`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 0 9538413 7 5`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Doppelganger – Beverley Hood`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition minigraph (number 13 of series), 2004`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Essay by Francis McKee`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Streetlevel Photoworks, Glasgow, UK  `}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Hybrid Invention`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Explorations in Art & Technology (First Edition), 2002`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Edited by Linda Candy and Ernest Edmonds`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Springer Verlag, London, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN 978-1-4471-0197-0`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        <MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.springer.com/gb/book/9781852335458",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://www.springer.com/gb/book/9781852335458`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Message Sent`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition Catalogue, 2001`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by the Travelling Gallery, Edinburgh, UK  `}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Blue Skies`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition publication, 2001`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Essay by Francis McKee`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Stills Gallery, Edinburgh, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 0-9064-5815-3`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The National Review of Live Art`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Festival Catalogue, 2000`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Commissioned artist essay: Beverley Hood - La Cumparista by Simon Yuill`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by New Moves International, Glasgow, UK  `}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`An Invitation to Kiss`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition Catalogue, 2000`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Essays by Kaz McIntee, Si Yuill, Karen Scopa, Adrianne Wall and Mark Waddell`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Generator, Dundee, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 0 95 33294 10`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`RADAR`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Exhibition catalogue, 1998`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Published by Generator, Dundee, UK`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`ISBN: 0 95 33294 0 2`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Publications",
  "slug": "publications",
  "cover": "./mountains.jpg"
};
    